import { ChatbotMessageEvent } from '~/event/message'

/**
 * Chatbot script
 *
 * Wrap it in an IIFE to avoid polluting the global scope
 *
 * @see https://developer.mozilla.org/en-US/docs/Glossary/IIFE
 */
// eslint-disable-next-line @typescript-eslint/no-extra-semi
;(() => {
  /////////////////////////////////////////////////
  // Core

  const APP_URL = process.env.APP_URL
  const APP_VERSION = process.env.APP_VERSION
  const CHAT_URL = `${APP_URL}/chat`

  const defaultLanguage = 'en'

  const languages = {
    en: 'EN',
    sl: 'SLO',
    hr: 'HR',
    bs: 'BIH',
    at: 'DE',
    it: 'IT',
    ru: false, // disable
  } as const

  // check if language is supported
  const [path] = window.location.pathname.split('/').filter(Boolean)
  const lng = Object.keys(languages).includes(path) ? path : defaultLanguage
  const language = languages[lng as keyof typeof languages]

  // skip if language is not supported
  if (!language) {
    console.log(`Donat Chatbot disabled for language ${lng}`)
    return
  }

  // language is supported, load chatbot
  console.log(`Donat Chatbot version ${APP_VERSION}`)

  const _language = language
  let _button: HTMLButtonElement | null = null
  let _chat: HTMLIFrameElement | null = null

  /////////////////////////////////////////////////
  // Styles

  const _css = `
.donat-chatbot__button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: fixed;
  bottom: 4.25rem;
  right: 1rem;
  padding: 1rem;
  background: #00594E;
  border-radius: 12px;
  border: none;
  z-index: 9998;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: background .25s ease-out;
}
.donat-chatbot__button-text {
  display: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4375;
  text-transform: uppercase;
  font-family: "Konkret Grotesk Pro", arial;
}
.donat-chatbot__button-badge {
  position: absolute;
  top: -10px;
  left: -10px;
  display: inline-flex;
  gap: 4px;
  padding: 6px 8px;
  background: #B2A286;
  border-radius: 9999px;
  white-space: nowrap;
}
.donat-chatbot__button-badge span {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: #fff;
}
.donat-chatbot__button-badge span:last-child {
  display: none;
}
.donat-chatbot__button-icon {
  width: 24px;
  height: 24px;
}
.donat-chatbot__button-icon svg {
  display: block;
}
/*
@media (min-width: 990px) {
  .donat-chatbot__button-text {
    display: inline;
  }
  .donat-chatbot__button-badge{
    top: -15px;
    left: -13px;
  }
  .donat-chatbot__button-badge span:last-child {
    display: inline;
  }
  .donat-chatbot__button-icon {
    width: 20px;
    height: 20px;
  }
}
*/
.donat-chatbot__button:hover {
  background: #004F44;
}
.donat-chatbot__button:focus {
  background: #004F44;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px, rgb(0, 125, 105) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}
.donat-chatbot__button--closed {
  display: none;
}
@media (min-width: 990px) {
  .donat-chatbot__button {
    bottom: 16px;
  }
}
.donat-chatbot__chat {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 824px;
  max-height: 85vh;
  border: none;
  box-shadow: 0px 4px 21px 0px #00000033;
  z-index: 10000;
}
.donat-chatbot__chat--open {
  opacity: 1;
  pointer-events: auto;
  z-index: 10000;
}
@media (min-width: 641px) {
  .donat-chatbot__chat {
    bottom: 64px;
    right: 16px;
    width: 448px;
    max-height: 85vh;
    border-radius: 8px;
    overflow: hidden;
  }
}
@media (min-width: 990px) {
  .donat-chatbot__chat {
    bottom: 16px;
  }
}
  ` //.replace(/\s/g, '')

  const _style = document.createElement('style')
  parent.document.head.appendChild(_style)
  _style.setAttribute('type', 'text/css')
  _style.appendChild(document.createTextNode(_css))

  /////////////////////////////////////////////////
  // Functions

  function createButton() {
    _button = document.createElement('button')

    const badge = document.createElement('span')
    badge.setAttribute('class', 'donat-chatbot__button-badge')
    badge.innerHTML = `<span>AI</span><span>Powered</span>`

    const text = document.createElement('span')
    text.setAttribute('class', 'donat-chatbot__button-text')
    text.textContent = 'Chat with us'

    const icon = document.createElement('span')
    icon.setAttribute('class', 'donat-chatbot__button-icon')
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path d="M12.8389 7.1664L8.5993 11.4513L3.77737 8.43532C3.08649 8.00308 3.23021 6.95367 4.01166 6.72514L15.4602 3.37243C16.1758 3.1627 16.8389 3.83174 16.6264 4.54964L13.2393 15.9902C13.0073 16.7727 11.9639 16.9126 11.5357 16.2187L8.59705 11.452" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`

    _button.appendChild(badge)
    _button.appendChild(text)
    _button.appendChild(icon)

    _button.setAttribute('type', 'button')
    _button.setAttribute('class', 'donat-chatbot__button')
    _button.addEventListener('click', showChat)

    parent.document.body.appendChild(_button)
  }

  function createChat() {
    const params = new URLSearchParams()
    params.append('language', _language)
    const url = `${CHAT_URL}?${params.toString()}`
    _chat = document.createElement('iframe')
    _chat.setAttribute('src', url)
    _chat.setAttribute('class', 'donat-chatbot__chat')
    _chat.classList.add('donat-chatbot__chat--open')
    parent.document.body.appendChild(_chat)
  }

  function showButton() {
    _chat?.classList.remove('donat-chatbot__chat--open')
    if (_button) {
      _button.classList.remove('donat-chatbot__button--closed')
    } else {
      createButton()
    }
  }

  function showChat() {
    _button?.classList.add('donat-chatbot__button--closed')
    if (_chat) {
      _chat.classList.add('donat-chatbot__chat--open')
    } else {
      createChat()
    }
  }

  function isChatbotMessage(event: MessageEvent): event is ChatbotMessageEvent {
    return (
      event &&
      event.data &&
      typeof event.data === 'object' &&
      'chatbot' in event.data &&
      event.origin === APP_URL
    )
  }

  /////////////////////////////////////////////////
  // Event listeners

  parent.document.addEventListener(
    'readystatechange',
    function onReady() {
      if (document.readyState === 'complete') {
        showButton()
      }
    },
    { once: true },
  )

  window.addEventListener('message', function onMessage(event: MessageEvent) {
    if (isChatbotMessage(event)) {
      const { chatbot } = event.data
      if (chatbot === 'open') showChat()
      if (chatbot === 'close') showButton()
    }
  })

  /////////////////////////////////////////////////
  // Public API

  parent.DonatChatbot = {
    open: showChat,
    close: showButton,
  }
})()

declare global {
  interface Window {
    DonatChatbot: {
      open: () => void
      close: () => void
    }
  }
}
